.button {
    display: inline-block;
    padding: 20px 45px;
    width: fit-content;
    background-color: transparent;
    color: var(--black);
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid var(--black);
    text-transform: uppercase;
    font-weight: 300;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, font-weight 0.1s;
}

.button:hover {
    background-color: var(--black);
    color: var(--white);
    font-weight: 400;
}