.home_container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: calc(var(--font-size) * 1.25);
    align-content: center;
    height: 100vh;
}

.home_left {
    grid-column: 2 / 6;
    display: flex;
    flex-direction: column;
}

.home_left h1 {
    font-size: calc(var(--font-size) * 2.5);
    line-height: 1.25;
    letter-spacing: -0.06em;
}

.home_left h2 {
    font-size: calc(var(--font-size) * 2);
    font-weight: 300;
    letter-spacing: -0.125rem;
}

.home_right {
    grid-column: 7 / 12;
}